import {Component, OnInit} from '@angular/core';
import {BlogServicesService} from '../../services/blog-services.service';
import {ToastService} from '../../services/toast-service.service';

@Component({
  selector: 'app-leave-comment',
  templateUrl: './leave-comment.component.html',
  styleUrls: ['./leave-comment.component.css']
})
export class LeaveCommentComponent implements OnInit {
  webConfig: any = {};
  categories: any[] = []; // Danh mục loại hình kinh doanh
  feedbackData: any = {
    context: '',
    name_sender: '',
    email_sender: '',
    category_level1: null, // ID của loại hình kinh doanh
    stage: ''
  };

  constructor(private blogService: BlogServicesService,
              private toastService: ToastService) {
  }

  ngOnInit(): void {
    this.loadWebConfig();
    this.getCategoryLevel1(); // Gọi API để lấy danh sách loại hình kinh doanh
    // Đặt giá trị mặc định ban đầu cho category_level1 và stage
    this.feedbackData.category_level1 = '';
    this.feedbackData.stage = '';
  }

  // Lấy cấu hình web
  loadWebConfig() {
    this.blogService.getWebConfig().subscribe(response => {
      if (response.errorCode === 0) {
        this.webConfig = response.data;
      }
    });
  }

  // Gọi API để lấy danh mục loại hình kinh doanh
  getCategoryLevel1() {
    this.blogService.getCategoryLevel1().subscribe(response => {
      if (response.errorCode === 0) {
        this.categories = response.data; // Gán danh sách loại hình kinh doanh vào biến categories
      }
    });
  }

  submitFeedback(feedbackForm: any) {
    // Validate form trước khi gửi nếu cần
    if (!this.feedbackData.context || !this.feedbackData.name_sender) {
      return;
    }

    // Gọi API tạo mới feedback qua BlogServicesService
    this.blogService.createFeedback(this.feedbackData).subscribe(response => {
      if (response.errorCode === 0) {
        // Hiển thị toast khi gửi thành công
        this.toastService.showToast('Phản hồi của bạn đã được gửi thành công!');

        // Xóa dữ liệu trong form (reset lại feedbackData)
        this.feedbackData = {
          context: '',
          name_sender: '',
          email_sender: '',
          category_level1: null, // ID của loại hình kinh doanh
          stage: ''
        };

        // Reset trạng thái form (xóa các thông tin đã nhập)
        feedbackForm.resetForm();  // Reset form
      } else {
        // Hiển thị lỗi (nếu có)
        this.toastService.showToast('Đã xảy ra lỗi. Vui lòng thử lại.');
      }
    });
  }
}
