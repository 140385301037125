import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BlogServicesService} from '../../services/blog-services.service';
import {OwlOptions} from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.css'
})
export class HomePageComponent implements OnInit, AfterViewInit {
  webConfig: any = {};
  isLoading = true;
  showFilterModal = false;

  // Actual category data used outside the modal
  categories: any[] = [];
  level2Categories: any[] = [];
  level3Categories: any[] = [];

  // Active selections used outside the modal
  activeCategory = 0;
  activeLevel2 = -1;
  activeLevel3 = -1;

  // Temporary selections used inside the modal
  tempActiveCategory = 0;
  tempActiveLevel2 = -1;
  tempActiveLevel3 = -1;

  // For selected checkboxes
  subCategories: any[] = [];
  level2subCategories: any[] = [];
  selectedLevel2Ids: number[] = [];
  selectedLevel3Ids: number[] = [];

  blogs: any[] = []; // Blog data
  page = 1;
  size = 8;

  events: any[] = [];  // Dữ liệu sự kiện
  timeFilter = 'current_month';  // Bộ lọc mặc định cho "Tháng này"
  customOptions: OwlOptions = {
    loop: true,
    margin: 20,
    autoplay: true,
    autoplayTimeout: 7000,
    autoplayHoverPause: true,
    nav: true,
    dots: false,
    navText: ['<', '>'],
    center: true, // Căn giữa các card
    autoHeight: false, // Đảm bảo chiều rộng tự động theo nội dung của card
    autoWidth: true, // Đảm bảo chiều rộng tự động theo nội dung của card
    responsive: {
      0: {
        items: 1, // Hiển thị 1 card trên màn hình nhỏ
        center: true, // Căn giữa cho mobile
      },
      600: {
        items: 2, // Hiển thị 2 card trên màn hình trung bình
        center: true, // Căn giữa khi có 2 card
      },
      1000: {
        items: 4 // Hiển thị 4 card trên màn hình lớn
      }
    }
  };

  blogs_newest: any[] = []; // Blog data
  constructor(private blogService: BlogServicesService) {
    this.loadWebConfig();
  }

  ngOnInit(): void {
    this.loadNewestBlog();
    this.loadCategories();
    this.loadEvents();  // Tải sự kiện mặc định
  }

  ngAfterViewInit(): void {
  }

  loadWebConfig() {
    this.blogService.getWebConfig().subscribe(response => {
      if (response.errorCode === 0) {
        this.webConfig = response.data;
      }
    });
  }

  // Load categories from the API
  loadCategories() {
    this.blogService.getCategoryBlog().subscribe(response => {
      if (response.errorCode === 0) {
        this.categories = [{id: 0, name: 'Tất cả các chủ đề', level_2: []}, ...response.data];
        this.selectCategory(0);
      }
    });
  }

  // Select category (level 1) for the main view
  selectCategory(index: number, is_load_blog: boolean = true): void {
    this.activeCategory = index;

    if (index === 0) {
      // Gộp tất cả các level_2 từ categories
      const allLevel2 = this.categories.reduce((acc, category) => acc.concat(category.level_2 || []), []);

      // Gán danh sách không trùng lặp vào level2Categories
      this.level2Categories = Array.from(new Map(allLevel2.map(item => [item.id, item])).values());
    } else {
      this.level2Categories = this.categories[index]?.level_2 || [];
    }
    this.selectLevel2(-1);
    if (is_load_blog) {
      this.loadBlogs();
    }
  }

  // Select level 2 in the main view
  selectLevel2(index: number, is_load_blog: boolean = true): void {
    this.activeLevel2 = index;
    this.level3Categories = this.level2Categories[index]?.level_3 || [];
    this.selectLevel3(-1);
    if (is_load_blog) {
      this.loadBlogs();
    }
  }

  // Select level 3 in the main view
  selectLevel3(index: number, is_load_blog: boolean = true): void {
    this.activeLevel3 = index;
    if (is_load_blog) {
      this.loadBlogs();
    }
  }

  // Fetch blog data based on selected filters
  loadBlogs(): void {
    const level1Id = this.categories[this.activeCategory]?.id || 0;
    const level2Id = this.level2Categories[this.activeLevel2]?.id || 0;
    const level3Id = this.level3Categories[this.activeLevel3]?.id || 0;

    const payload = {
      level_1: level1Id,
      level_2: level2Id,
      level_3: level3Id,
      page: this.page,
      size: this.size,
    };

    this.blogService.getListBlogByCategory(payload).subscribe(response => {
      if (response.errorCode === 0) {
        this.blogs = response.data;
        this.isLoading = false;
      }
    });
  }

  // Open the modal and copy current selections to temporary variables
  openFilterModal(): void {
    this.showFilterModal = true;

    // Copy the current selections to temporary selections for the modal
    this.tempActiveCategory = this.activeCategory;
    this.tempActiveLevel2 = this.activeLevel2;
    this.tempActiveLevel3 = this.activeLevel3;

    // Update the level 2 and level 3 categories based on the current tempActiveCategory
    this.updateModalCategories();
  }

  // Close the modal without saving changes
  closeFilterModal(): void {
    this.showFilterModal = false;
  }

  // When switching between level 1 categories in the modal
  updateModalCategories(): void {
    const selectedCategory = this.categories[this.tempActiveCategory];
    if (selectedCategory) {
      // Update level 2 categories based on the selected category
      this.level2subCategories = selectedCategory.level_2 || [];
      this.level2Categories = selectedCategory.level_2 || [];
      if (selectedCategory.id === 0) {
        // Gộp tất cả các level_2 từ categories
        const allLevel2 = this.categories.reduce((acc, category) => acc.concat(category.level_2 || []), []);
        // Gán danh sách không trùng lặp vào level2Categories
        this.level2Categories = Array.from(new Map(allLevel2.map(item => [item.id, item])).values());
        this.level2subCategories = this.level2Categories;
      }
    } else {
      // Fallback if no level 2 categories found
      this.level2subCategories = [];
    }

    this.updateSubCategories();  // Update checkboxes when a new category is selected
  }

  // Update the subcategories (checkboxes) based on selected level 1 and level 2 in the modal
  updateSubCategories(): void {
    this.subCategories = [];

    // Populate subcategories based on level 2 and level 3
    this.level2subCategories.forEach(level2 => {
      this.subCategories.push({id: level2.id, name: level2.name, isChecked: false});

      level2.level_3.forEach(level3 => {
        this.subCategories.push({id: level3.id, name: level3.name, isChecked: false});
      });
    });
  }

  // Toggle checkbox selection in the modal
  toggleSubCategory(id: number, isChecked: boolean): void {
    const category = this.subCategories.find(sub => sub.id === id);
    if (category) {
      category.isChecked = isChecked;
    }
  }

  // Reset filters in the modal (does not affect the main view)
  resetFilters(): void {
    this.subCategories.forEach(sub => {
      (sub.isChecked = false);
    });
    this.selectedLevel2Ids = [];
    this.selectedLevel3Ids = [];
    this.updateSubCategories();
  }

  // Apply the filters from the modal to the main view
  applyFilters(): void {
    // Replace the main selections with the temporary selections from the modal
    this.activeCategory = this.tempActiveCategory;
    this.activeLevel2 = this.tempActiveLevel2;
    this.activeLevel3 = this.tempActiveLevel3;

    // Extract selected level 2 and level 3 categories from checkboxes
    this.selectedLevel2Ids = this.subCategories
      .filter(sub => sub.isChecked && this.isLevel2(sub.id))
      .map(sub => sub.id);

    this.selectedLevel3Ids = this.subCategories
      .filter(sub => sub.isChecked && !this.isLevel2(sub.id))
      .map(sub => sub.id);
    const payload = {
      list_level_2: this.selectedLevel2Ids.join(','),
      list_level_3: this.selectedLevel3Ids.join(','),
      page: this.page,
      size: this.size,
    };

    // Call the API to get blogs with the selected filters
    this.blogService.getListBlogByCategory(payload).subscribe(response => {
      if (response.errorCode === 0) {
        this.blogs = response.data;
        this.isLoading = false;
      }
    });

    this.closeFilterModal(); // Close the modal after applying filters
  }

  // Helper method to check if an id is a level 2 category
  isLevel2(id: number): boolean {
    return this.level2subCategories.some(level2 => level2.id === id);
  }

  // Gọi API để lấy sự kiện
  loadEvents(): void {
    const payload = {
      page: 1,
      size: 12,
      time_str: this.timeFilter
    };

    this.isLoading = true;
    this.blogService.getListEvent(payload).subscribe(
      (response) => {
        if (response.errorCode === 0) {
          this.events = response.data;
          this.isLoading = false;
        }
      },
      (error) => {
        this.isLoading = false;
        console.error('Error fetching events:', error);
      }
    );
  }

  // Thay đổi bộ lọc thời gian (tuần này, tháng này, 3 tháng tới)
  onTimeFilterChange(event: any): void {
    const selectedValue = event.target.value;
    switch (selectedValue) {
      case '1':
        this.timeFilter = 'current_week';
        break;
      case '2':
        this.timeFilter = 'current_month';
        break;
      case '3':
        this.timeFilter = '3_month';
        break;
      default:
        this.timeFilter = 'all';
    }
    this.loadEvents();
  }

  onSelectCategoryChange(event: Event): void {
    const selectedIndex = (event.target as HTMLSelectElement).value;
    this.selectCategory(Number(selectedIndex));
  }

  loadNewestBlog(): void {
    const payload = {
      page: 1,
      size: 4,
    };
    this.blogService.getBlogNewest(payload).subscribe(response => {
      if (response.errorCode === 0) {
        this.blogs_newest = response.data;
      }
    });
  }
}


