<!-- Service Detail -->
<section class="service_detail position-relative">
  <div class="container">
    <!-- Breadcrumb Navigation -->
    <div class="breadcrumb-nav ml-2">
      <a href="/" class="text-decoration-none">
        <span class="mr-2">Trang chủ</span>
      </a>
      <i class="fa-solid fa-chevron-right"></i> <!-- Mũi tên -->
      <a [href]="end_point" class="text-decoration-none">
        <span class="mr-2">{{ des_name }}</span>
      </a>
      <i class="fa-solid fa-chevron-right"></i> <!-- Mũi tên -->
      <span class="mr-2 text-orange">{{ supplier.name }}</span>
    </div>

    <!-- Category Tag -->
    <div class="category-tags ml-2">
      <ng-container *ngFor="let category of supplier.category">
        <span class="category-tag">{{ category }}</span>
      </ng-container>
    </div>

    <div class="row">
      <div class="col-12 mx-auto">
        <div class="service_content text-center" data-aos="fade-up">
          <h2>{{ supplier.name }}</h2>
          <figure class="icon">
            <img [src]="supplier.logo" alt="image" class="img-fluid"/>
          </figure>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="main-box">
          <!-- Binding phần mô tả ngắn -->
          <p class="text1 text-size-16" data-aos="fade-up">{{ supplier.description }}</p>

          <!-- Binding phần nội dung chi tiết với innerHTML -->
          <div [innerHTML]="supplier.content" class="supplier-content" data-aos="fade-up"></div>

          <!-- Thông tin liên hệ -->
          <div class="contact-info" data-aos="fade-up">
            <p><i class="fa-solid fa-industry"></i> {{ supplier.industry }}</p>
            <p><i class="fa-solid fa-location-dot"></i> {{ supplier.location }}</p>
            <p><i class="fa-solid fa-phone"></i> {{ supplier.phone }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
