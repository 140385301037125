import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BlogServicesService} from '../../services/blog-services.service';

interface SearchResult {
  url: string;
  image: string;
  category: string;
  title: string;
  description: string;
  publication_date?: string;
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  webConfig: any = {};
  menus: any[] = [];
  query: string = '';
  selectedCategory: string = 'all';
  nameCategory: string = '';
  typeCategory: string = '';
  page: number = 1;
  size: number = 8;
  totalPage = 1;
  totalRecord = 0;
  filteredResults = [];

  constructor(
    private route: ActivatedRoute,
    private blogService: BlogServicesService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.query = params['query'] || '';
      this.search(); // Gọi API tìm kiếm khi trang load
    });
    this.loadWebConfig();
  }

  loadWebConfig() {
    this.blogService.getWebConfig().subscribe(response => {
      if (response.errorCode === 0) {
        this.webConfig = response.data;
        // Lọc các menu có name !== null hoặc end_point !== 'me'
        this.menus = this.webConfig.menus.filter((menu: any) =>
          menu.name !== null && menu.end_point !== 'me'
        );
      }
    });
  }

  filterResults(menu: any) {
    this.selectedCategory = menu.end_point;
    this.nameCategory = menu.name;
    this.typeCategory = menu.type;
    this.page = 1; // Đặt lại trang về 1 khi chuyển danh mục
    this.filteredResults = []
    this.search(true);
  }

  search(loadMore: boolean = false) {
    // Thực hiện tìm kiếm với API
    const trimmedQuery = this.query.trim();

    if (trimmedQuery) {
      const payload = {
        type: this.typeCategory,
        key_word: trimmedQuery,
        page: this.page,
        size: this.size
      };
      this.blogService.searchBlog(payload).subscribe(response => {
        if (response.errorCode === 0) {
          this.filteredResults = loadMore ? [...this.filteredResults, ...response.data] : response.data; // Gộp kết quả mới vào danh sách
          this.totalRecord = response.index.total_record;
          this.totalPage = Math.ceil(this.totalRecord / this.size); // Cập nhật số trang tối đa
        }
      });
    }
  }

  canLoadMore(): boolean {
    return this.page < this.totalPage; // Kiểm tra xem còn trang để load nữa không
  }

  loadMore() {
    if (this.canLoadMore()) {
      this.page++; // Tăng số trang lên
      this.search(true); // Gọi lại API với trang mới
    }
  }
}
