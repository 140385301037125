import {Component, OnInit} from '@angular/core';
import * as AOS from 'aos';
import {LoadingService} from './services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private loadingService: LoadingService) {
  }

  loading$ = this.loadingService.loading$;

  ngOnInit(): void {
    AOS.init();
  }
}
