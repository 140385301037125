<section class="comment-content" data-aos="fade-up" data-aos-duration="700">
  <div class="container">
    <h2 class="section-title" *ngFor="let menu of webConfig.menus">
      <!-- Kiểm tra nếu end_point == 'feedback' -->
      <ng-container *ngIf="menu.end_point === 'feedback'">
        {{ menu.des_main }}
      </ng-container>
    </h2>

    <p class="section-description" *ngFor="let menu of webConfig.menus">
      <ng-container *ngIf="menu.end_point === 'feedback'">
        {{ menu.des_extra }}
      </ng-container>
    </p>

    <!-- Form feedback -->
    <form (ngSubmit)="submitFeedback(feedbackForm)" #feedbackForm="ngForm" novalidate>
      <div class="row">
        <div class="col-12">
          <div class="form-group mb-0">
            <!-- Hiển thị lỗi nếu trường context không hợp lệ -->
            <div *ngIf="context.invalid && context.touched" class="text-danger">
              Vui lòng nhập nội dung phản hồi.
            </div>
            <textarea class="form_style" placeholder="Nhập nội dung..." rows="3" name="msg"
                      [(ngModel)]="feedbackData.context" required #context="ngModel"></textarea>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-group mb-0">
            <!-- Hiển thị lỗi nếu trường name không hợp lệ -->
            <div *ngIf="name.invalid && name.touched" class="text-danger">
              Vui lòng nhập tên của bạn.
            </div>
            <input type="text" class="form_style" placeholder="Tên của bạn" name="name"
                   [(ngModel)]="feedbackData.name_sender" required #name="ngModel">
          </div>
        </div>
      </div>

      <!-- Loại hình kinh doanh (chọn từ danh mục) -->
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <div class="form-group mb-0">
            <select class="form_style text-mb" name="type" [(ngModel)]="feedbackData.category_level1" required>
              <option value="" disabled selected>Chọn loại hình kinh doanh</option>
              <option *ngFor="let category of categories" [value]="category.id">{{ category.name }}</option>
            </select>
          </div>
        </div>

        <!-- Giai đoạn kinh doanh -->
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <div class="form-group mb-0">
            <select class="form_style text-mb" name="stage" [(ngModel)]="feedbackData.stage" required>
              <option value="" disabled selected>Chọn giai đoạn kinh doanh</option>
              <option value="plan">Lên kế hoạch</option>
              <option value="open">Mở quán</option>
              <option value="active">Đang hoạt động</option>
            </select>
          </div>
        </div>
      </div>

      <div class="button text-center">
        <button type="submit" class="post_comment" [disabled]="feedbackForm.invalid">Gửi</button>
      </div>
    </form>
  </div>
</section>
