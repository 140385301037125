import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Title} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class BlogServicesService {

  private webConfigData: any = {};  // Biến lưu trữ dữ liệu đã lấy từ API

  constructor(private http: HttpClient, private titleService: Title) {
  }

  // Hàm gọi API nếu dữ liệu chưa có
  getWebConfig(): Observable<any> {
    return this.http.post('/getWebConfig', {});
  }

  // Hàm trả về dữ liệu trực tiếp
  getStoredWebConfig(): any {
    return this.webConfigData;
  }

  getCategoryBlog(): Observable<any> {
    return this.http.post('/getCategoryBlog', {});
  }

  getListBlogByCategory(payload: any) {
    return this.http.post<any>('/getListBlogByCategory', payload);
  }

  getListNewByCategory(payload: any) {
    return this.http.post<any>('/getListNewByCategory', payload);
  }

  getListDocumentByCategory(payload: any) {
    return this.http.post<any>('/getListDocumentByCategory', payload);
  }

  getDocumentDetail(payload: any) {
    return this.http.post<any>('/getDocumentDetail', payload);
  }

  getBlogDetail(payload: any): Observable<any> {
    return this.http.post<any>('/getBlogDetail', payload);
  }

  getNewDetail(payload: any): Observable<any> {
    return this.http.post<any>('/getNewDetail', payload);
  }

  getEventDetail(payload: any): Observable<any> {
    return this.http.post<any>('/getEventDetail', payload);
  }

  getListEvent(payload: any): Observable<any> {
    return this.http.post<any>('/getListEvent', payload);
  }

  getListNew(payload: any): Observable<any> {
    return this.http.post<any>('/getListNew', payload);
  }

  getBlogNewest(payload: any): Observable<any> {
    return this.http.post<any>('/getBlogNewest', payload);
  }

  updateBlogView(payload: any): Observable<any> {
    return this.http.post<any>('/updateBlogView', payload);
  }

  // Hàm lấy danh sách loại hình kinh doanh
  getCategoryLevel1(): Observable<any> {
    return this.http.get<any>('/getCategoryLevel1');
  }

  getCategorySuppliers(): Observable<any> {
    return this.http.get<any>('/getCategorySuppliers');
  }

  getVietnamProvinces(): Observable<any> {
    return this.http.get<any>('/getVietnamProvinces');
  }

  getAboutMeConfig(): Observable<any> {
    return this.http.get<any>('/getAboutMeConfig');
  }

  // Hàm gửi feedback (mới thêm)
  createFeedback(feedbackData: any): Observable<any> {
    return this.http.post<any>('/createFeedback', feedbackData);
  }

  getListSuppliers(payload: any): Observable<any> {
    return this.http.post<any>('/getListSuppliers', payload);
  }

  searchListSuppliers(payload: any): Observable<any> {
    return this.http.post<any>('/searchListSuppliers', payload);
  }

  getSupplierDetail(payload: any): Observable<any> {
    return this.http.post<any>('/getSupplierDetail', payload);
  }

  searchBlog(payload: any): Observable<any> {
    return this.http.post<any>('/searchBlog', payload);
  }
}
