<div class="document-detail-container">
  <!-- Header với hình nền và nội dung chi tiết -->
  <div class="document-header" [ngStyle]="{'background-image': 'url(' + webConfig.thumbnail_index_url + ')'}">
    <div class="overlay"></div>
    <div class="document-content">
      <div class="container">
        <h1>{{document.name}}</h1>
        <h2>{{document.description}}</h2>
        <div class="document-info">
          <span><i class="fa-solid fa-icons"></i>  Định dạng: {{document.type}}</span>
          <span><i class="fa fa-download"></i> {{document.view}} lượt tải</span>
        </div>
        <div class="document-actions">
          <button class="share-button" (click)="copyToClipboard()">
            <i class="fa fa-copy"></i> Copy link
          </button>
          <button class="download-button"  (click)="downloadFile(document.link)">
            <i class="fa fa-download"></i> Tải xuống
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
