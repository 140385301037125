<header class="header" (click)="handleClickOutside($event)">
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light p-0">
      <!-- Brand Logo -->
      <a class="navbar-brand" href="/">
        <figure class="logo mb-0">
          <img [src]="webConfig.logo" alt="Logo" class="img-fluid">
        </figure>
      </a>

      <!-- Search Icon for Mobile -->
      <div class="search-icon mobile" (click)="toggleSearch()">
        <i class="fa fa-search"></i>
      </div>

      <!-- Mobile Menu Toggle Button -->
      <button class="navbar-toggler collapsed mobile-menu-toggler" type="button" (click)="toggleMenu()"
              aria-controls="navbarSupportedContent" aria-expanded="false"
              aria-label="Toggle navigation">
        <span class="fa fa-bars"></span>
      </button>

      <!-- Main Navigation (Collapsible) -->
      <div [ngClass]="{'show-mobile-menu': isMenuOpen}" *ngIf="!showSearch" id="navbarSupportedContent"
           class="navbar-collapse mobile-menu-collapse">
        <!-- Mobile Search Box -->
        <span class="close-menu-bobile mobile" (click)="closeMenuMobile()"><i class="fa-solid fa-xmark" ></i></span>

        <!-- Menu Items -->
        <ul class="navbar-nav ml-auto mobile-nav">
          <ng-container *ngFor="let menu of webConfig.menus">
            <li class="nav-item" [ngClass]="{'active': isActive(menu)}">
              <a class="nav-link" [href]="menu.end_point">{{ menu.name }}</a>
            </li>
          </ng-container>
        </ul>
        <div class="language-switcher mt-3 mobile">
          <a [href]="webConfig.community_link" target="_blank" class="nav-link"><i class="fa-brands fa-facebook-f social-networks"></i></a>
          <a [href]="webConfig.thread_link" target="_blank" class="nav-link"><i class="fa-brands fa-threads social-networks"></i></a>
          <a [href]="webConfig.tiktok_link"  target="_blank" class="nav-link"><i class="fa-brands fa-tiktok"></i></a>
        </div>
      </div>

      <!-- Search Icon for PC -->
      <div class="search-icon pc" (click)="toggleSearch()">
        <i class="fa fa-search"></i>
      </div>

      <!-- Search Box for PC -->
      <div class="search-box" *ngIf="showSearch">
        <input type="text" placeholder="Tìm kiếm" [(ngModel)]="searchQuery"/>
        <button (click)="search()"><i class="fa fa-search"></i></button>
        <span class="close-search" (click)="toggleSearch()">x</span>
      </div>
    </nav>
  </div>
</header>
