<div class="container">
  <div class="search-page-container">
    <div class="search-bar">
      <input type="text" placeholder="Bạn đang muốn tìm kiếm điều gì?" [(ngModel)]="query"/>
      <button type="submit" class="search-button" (click)="search()">Tìm kiếm</button>
    </div>

    <div class="search-content">
      <!-- Sidebar cho các mục tìm kiếm -->
      <div class="row">
        <div class="col-2">
          <div class="search-categories">
            <ul *ngFor="let menu of menus">
              <li
                [class.active]="selectedCategory === menu.end_point"
                (click)="filterResults(menu)">
                <a><i class="fa-solid fa-angles-right mr-1"></i> {{ menu.name }}</a>
              </li>

            </ul>
          </div>
        </div>
        <div class="col-10">
          <!-- Kết quả tìm kiếm -->
          <div class="search-results">
            <h3 *ngIf="selectedCategory !== 'all'">Kết quả tìm kiếm {{ nameCategory }} cho "{{ query }}"</h3>
            <div *ngFor="let result of filteredResults" class="search-result-item">
              <figure class="result-image">
                <img [src]="result.image" alt="image" class="img-fluid" loading="lazy"/>
              </figure>
              <div class="result-details">
                <div class="span-i-con">
                  <span class="text-size-14 tag-summary text-orange"> {{ result.category }}</span>
                  <span class="ml-2 text-size-14">{{ result.publication_date}}</span>
                </div>
                <a [href]="result.url"><h4 class="result-title max-1-line">{{ result.title }}</h4></a>
                <p class="result-description max-3-line">{{ result.description }}</p>
              </div>
            </div>
          </div>
          <!-- Load More Button -->
          <div class="load-more d-flex justify-content-center mt-4" *ngIf="canLoadMore()">
            <a (click)="loadMore()" class="default-btn hover-effect">Xem thêm <i
              class="fa-solid fa-arrow-down"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="search-page-container-mobile">
    <div class="search-bar-mobile">
      <input type="text" placeholder="Bạn đang muốn tìm kiếm điều gì?" [(ngModel)]="query"/>
      <button type="submit" class="search-button-mobile" (click)="search()"><i class="fa fa-search"></i></button>
    </div>

    <div class="search-content-mobile">
      <!-- Sidebar cho các mục tìm kiếm -->
      <div class="search-categories-mobile">
        <ul>
          <li *ngFor="let menu of menus"
              [class.active-mobile]="selectedCategory === menu.end_point"
              (click)="filterResults(menu)">
            <a>{{ menu.name }}</a>
          </li>
        </ul>
      </div>

      <!-- Kết quả tìm kiếm -->
      <div class="search-results-mobile">
        <h3 *ngIf="selectedCategory !== 'all'">Kết quả tìm kiếm {{ nameCategory }} cho "{{ query }}"</h3>
        <div *ngFor="let result of filteredResults" class="search-result-item-mobile">
          <img [src]="result.image" alt="{{ result.title }}" class="result-image-mobile"/>
          <div class="result-details-mobile">
            <div class="span-i-con">
              <span class="text-size-14 tag-summary text-orange"> {{ result.category }}</span>
              <span class="ml-2 text-size-14">{{ result.publication_date}}</span>
            </div>
            <h4 class="result-title-mobile max-1-line">{{ result.title }}</h4>
            <p class="result-description-mobile max-3-line">{{ result.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
