import {Component} from '@angular/core';
import {BlogServicesService} from "../../services/blog-services.service";
import {ActivatedRoute} from "@angular/router";
import {ToastService} from "../../services/toast-service.service";

@Component({
  selector: 'app-document-detail',
  templateUrl: './document-detail.component.html',
  styleUrl: './document-detail.component.css'
})
export class DocumentDetailComponent {
  webConfig: any = {};
  document = {
    name: '',
    description: '',
    type: '',
    action: '',
    link: '',
    view: 0,
  };
  url = '';

  constructor(private blogService: BlogServicesService,
              private route: ActivatedRoute,
              private toastService: ToastService) {
  }

  ngOnInit(): void {
    this.loadWebConfig();
    const url = this.route.snapshot.paramMap.get('id');
    if (url) {
      this.url = url;
      this.getDocumentDetail(url);
    }
  }

  loadWebConfig() {
    this.blogService.getWebConfig().subscribe(response => {
      if (response.errorCode === 0) {
        this.webConfig = response.data;
      }
    });
  }

  getDocumentDetail(eventUrl: string): void {
    const payload = {url: `/tai-lieu/${eventUrl}`};

    this.blogService.getDocumentDetail(payload).subscribe(
      (response) => {
        if (response.errorCode === 0) {
          this.document = response.data
        }
      }
    );
  }

  // Hàm để tải file từ URL
  downloadFile(url: string): void {
    window.open(url, '_blank');
    this.updateView();
  }

  updateView(): void {
    const payload = {url: `/tai-lieu/${this.url}`};
    this.blogService.updateBlogView(payload).subscribe(response => {
    });
  }

  copyToClipboard() {
    const url = window.location.href; // Lấy URL hiện tại

    // Tạo một textarea tạm thời để chứa URL và sao chép nó
    const textarea = document.createElement('textarea');
    textarea.value = url;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    this.toastService.showToast('Sao chép đường dẫn thành công!');
  }
}
