import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {BlogServicesService} from '../../services/blog-services.service';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrl: './event-detail.component.css'
})
export class EventDetailComponent implements OnInit {
  eventDetail: any = {};  // Để lưu chi tiết sự kiện
  relatedEvents: any[] = [];  // Lưu các sự kiện liên quan
  isLoading = true;  // Trạng thái tải dữ liệu
  isLoadingRelated = true;  // Trạng thái tải các sự kiện liên quan
  currentUrl = '';

  webConfig: any = {};
  des_main = '';
  des_extra = '';
  des_name = '';
  end_point = '';
  constructor(
    private route: ActivatedRoute,
    private eventService: BlogServicesService,  // Service để gọi API
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.loadWebConfig();
    this.currentUrl = window.location.href;
    const url = this.route.snapshot.paramMap.get('id');
    if (url) {
      this.getEventDetail(url);
    }
  }

  loadWebConfig() {
    this.eventService.getWebConfig().subscribe(response => {
      if (response.errorCode === 0) {
        this.webConfig = response.data;
        this.extractMenuDescription();
      }
    });
  }

  extractMenuDescription() {
    const partnerMenu = this.webConfig.menus.find(menu => menu.end_point === 'su-kien');
    if (partnerMenu) {
      this.des_main = partnerMenu.des_main;
      this.des_name = partnerMenu.name;
      this.end_point = partnerMenu.end_point;
      // Assuming des_extra is another property you might be interested in
      this.des_extra = partnerMenu.des_extra || ''; // Default to empty if not available
    }
  }

  // Hàm gọi API để lấy chi tiết sự kiện
  getEventDetail(eventUrl: string): void {
    const payload = { url: `/su-kien/${eventUrl}` };

    this.eventService.getEventDetail(payload).subscribe(
      (response) => {
        if (response.errorCode === 0) {
          this.eventDetail = response.data;
          this.isLoading = false;
          this.getRelatedEvents();  // Gọi API để lấy sự kiện liên quan sau khi lấy chi tiết sự kiện
          this.titleService.setTitle(response.data.name)
        }
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  // Hàm gọi API để lấy danh sách sự kiện liên quan
  getRelatedEvents(): void {
    const payload = {
      page: 1,
      size: 4,
      url: this.eventDetail.url,
      time_str: "current_month"
    };

    this.eventService.getListEvent(payload).subscribe(
      (response) => {
        if (response.errorCode === 0) {
          this.relatedEvents = response.data;
          this.isLoadingRelated = false;
        }
      },
      (error) => {
        this.isLoadingRelated = false;
      }
    );
  }
}
