<div class="news-list-page blogpage-section background-gradient">
  <div class="container">
    <div class="row">
      <!-- Danh sách tin tức chính -->
      <div class="col-lg-8">
        <h4 class="section-title text-center mb-4">{{des_main}}</h4>
        <div class="news-list">
          <div class="news-item" *ngFor="let news of newsList">
            <div class="row">
              <div class="col-md-4">
                <div class="news-image">
                  <a [href]="news.url">
                    <img [src]="news.thumbnail_url" alt="news image" class="img-fluid" loading="lazy">
                  </a>
                </div>
              </div>
              <div class="col-md-8">
                <div class="news-content">
                  <a [href]="news.url" class="news-title max-1-line">
                    <h3>{{ news.title }}</h3>
                  </a>
                  <p class="news-date">{{ news.date }}</p>
                  <p class="news-summary max-3-line">{{ news.summary }}</p>
                </div>
                <div class="button-portion">
                  <div class="button">
                    <a class="mb-0 text-decoration-none text-loadmore" [href]="news.url" target="_blank">Xem bài viết <i
                      class="fa-solid fa-arrow-right"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Load More Button -->
        <div class="load-more d-flex justify-content-center mt-4" *ngIf="canLoadMore()">
          <a (click)="loadMore()" class="default-btn hover-effect">Xem thêm <i class="fa-solid fa-arrow-down"></i></a>
        </div>
      </div>

      <!-- Phần Đọc Nhiều (cột bên phải) -->
      <div class="col-lg-4">
        <div class="popular-news">
          <h4 class="section-title text-center mb-4">Đọc Nhiều</h4>
          <div class="popular-news-item" *ngFor="let popular of popularNewsList">
            <div class="row">
              <div class="col-4">
                <a [href]="popular.url">
                  <img [src]="popular.thumbnail_url" alt="popular news image" class="img-fluid" loading="lazy">
                </a>
              </div>
              <div class="col-8">
                <a [href]="popular.url" class="popular-news-title">{{ popular.title }}</a>
                <p class="popular-news-date">{{ popular.date }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
