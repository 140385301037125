import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {LoadingService} from './loading.service';
import {finalize} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor{

  constructor(private loadingService: LoadingService) { }

  // Hàm intercept thực hiện nhiệm vụ thêm api_url vào request
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingService.show();
    const apiUrl = environment.api_url;

    // Clone request và thêm api_url vào
    const modifiedReq = req.clone({
      url: `${apiUrl}${req.url}`,
      setHeaders: {
        'Content-Type': 'application/json'
      }
    });

    return next.handle(modifiedReq).pipe(
      finalize(() => this.loadingService.hide())
    );
  }


}
