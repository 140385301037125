import {Component, OnInit} from '@angular/core';
import {BlogServicesService} from '../../services/blog-services.service';
import {ActivatedRoute} from '@angular/router';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-suppliers-partner-detail',
  templateUrl: './suppliers-partner-detail.component.html',
  styleUrls: ['./suppliers-partner-detail.component.css']
})
export class SuppliersPartnerDetailComponent implements OnInit {
  supplier: any = {}; // Đối tượng chứa thông tin nhà cung cấp

  webConfig: any = {};
  des_main = '';
  des_extra = '';
  des_name = '';
  end_point = '';

  constructor(
    private blogService: BlogServicesService,
    private route: ActivatedRoute, // Để lấy URL từ route
    private titleService: Title
  ) {
  }

  ngOnInit(): void {
    this.loadWebConfig();
    this.loadSupplierDetail();
  }

  loadWebConfig() {
    this.blogService.getWebConfig().subscribe(response => {
      if (response.errorCode === 0) {
        this.webConfig = response.data;
        this.extractMenuDescription();
      }
    });
  }

  extractMenuDescription() {
    const partnerMenu = this.webConfig.menus.find(menu => menu.end_point === 'doi-tac');
    if (partnerMenu) {
      this.des_main = partnerMenu.des_main;
      this.des_name = partnerMenu.name;
      this.end_point = partnerMenu.end_point;
      // Assuming des_extra is another property you might be interested in
      this.des_extra = partnerMenu.des_extra || ''; // Default to empty if not available
    }
  }

  // Gọi API lấy chi tiết nhà cung cấp
  loadSupplierDetail(): void {
    // Lấy đường dẫn hiện tại từ URL
    const currentUrl = this.route.snapshot.paramMap.get('id');

    // Gọi API getSupplierDetail với URL
    const payload = {url: `/doi-tac/${currentUrl}`};
    this.blogService.getSupplierDetail(payload).subscribe(response => {
      if (response.errorCode === 0) {
        this.supplier = response.data; // Gán dữ liệu nhận được từ API vào biến supplier
        this.titleService.setTitle(response.data.name)
      }
    });
  }
}
