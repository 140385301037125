<div class="sub_banner position-relative">
  <!-- Sub banner -->
  <section class="sub_banner_con pc"
           [ngStyle]="{'background-image': 'url(' + webConfig.thumbnail_list_blog_url + ')'}">
  </section>
</div>

<div class="blog-posts blogpage-section background-gradient">
  <div class="container">


    <div class="search-container">
      <!-- Tiêu đề và mô tả -->
      <h2 class="section-title">
        {{des_main}}
      </h2>

      <p class="section-description">
        {{des_extra}}
      </p>


      <!-- Cấp 1 - Danh mục (Level 1) -->
      <div class="scroll-container">
        <div class="search-icons-lv1 pc">
          <p class="icon-lv1"
             *ngFor="let category of categories; let i = index"
             [class.active]="i === activeCategory"
             (click)="selectCategory(i)">
            {{ category.name }}
          </p>
        </div>


        <!-- Dropdown Select (sẽ hiển thị trên màn hình nhỏ) -->
        <select class="category-select" (change)="onSelectCategoryChange($event)">
          <option *ngFor="let category of categories; let i = index" [value]="i">
            {{ category.name }}
          </option>
        </select>

        <!-- Nút Bộ Lọc -->
        <button class="filter-button" (click)="openFilterModal()">
          <i class="fa-solid fa-filter"></i><span class="text"> Bộ Lọc</span>
        </button>
      </div>

      <!-- Cấp 2 - Danh mục con (Level 2) -->
      <div class="scroll-container">
        <div class="search-icons">
          <div class="icon"
               *ngFor="let level2 of level2Categories; let j = index"
               [class.active]="j === activeLevel2"
               (click)="selectLevel2(j)">
            <img [alt]="level2.name" [src]="level2.icon_url" loading="lazy" class="img-30"> {{ level2.name }}
          </div>

          <!-- Nút Scroll Next -->
          <button class="scroll-next">
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.75" y="0.75" width="42.5" height="42.5" rx="21.25" fill="#FFF8E1"></rect>
              <path d="M15.125 22H28.875" stroke="#FFCC33" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"></path>
              <path d="M23.25 16.375L28.875 22L23.25 27.625" stroke="#FFCC33" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round"></path>
              <rect x="0.75" y="0.75" width="42.5" height="42.5" rx="21.25" stroke="#FFCC33" stroke-width="1.5"></rect>
            </svg>
          </button>
        </div>
      </div>

      <!-- Cấp 3 - Menu con (Level 3) -->
      <div class="scroll-container">
        <div class="sub-category-container">
          <button class="sub-category"
                  *ngFor="let level3 of level3Categories; let k = index"
                  [class.active]="k === activeLevel3"
                  (click)="selectLevel3(k)">
            {{ level3.name }}
          </button>
        </div>
      </div>
    </div>

    <!-- end search -->

    <!-- Blog Section -->
    <div class="row wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
      <div id="blog" class="col-xl-12">
        <div class="row">
          <div *ngFor="let blog of blogs" class="col-xl-3 col-lg-6 col-md-6">
            <div class="blog-box fourcolumn-blog float-left w-100 post-item mb-4" data-aos="fade-up"
                 data-aos-duration="700">
              <div class="post-item-wrap position-relative">
                <div class="post-image">
                  <a [href]="blog.url" target="_blank">
                    <img [alt]="blog.name" [src]="blog.thumbnail_url" loading="lazy" class="img-fluid">
                  </a>
                  <!--post-image-->
                </div>
                <div class="lower-portion">
                  <div class="span-i-con">
                    <span class="text-size-14 tag-summary" ngbTooltip="{{blog.category_name}}"> {{ blog.category_name }}</span>
                    <span class="ml-2 text-size-14">{{ blog.publication_date}}</span>
                  </div>
                  <!-- Tiêu đề bài viết -->
                  <a [href]="blog.url" target="_blank" class="text-decoration-none text-a">
                    <h4 class="post-title">{{ blog.name }}</h4>
                  </a>

                  <!-- Bọc nội dung tóm tắt trong thẻ <a> -->
                    <p class="mb-0 text-size-16 blog-summary">
                      {{ blog.description }}
                    </p>
                </div>
                <div class="button-portion">
                  <div class="button">
                    <a class="mb-0 text-decoration-none text-loadmore" [href]="blog.url" target="_blank">Xem bài viết <i
                      class="fa-solid fa-arrow-right"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Loading Indicator -->
        <div *ngIf="isLoading" class="d-flex justify-content-center align-items-center" style="min-height: 300px;">
          <p>Loading...</p>
        </div>
        <!-- Load More Button -->
        <div class="load-more d-flex justify-content-center mt-4" *ngIf="canLoadMore()">
          <a (click)="loadMoreBlogs()" class="default-btn hover-effect">Xem thêm <i
            class="fa-solid fa-arrow-down"></i></a>
        </div>
      </div>
    </div>

    <!--    end blog section-->
  </div>
</div>

<!-- Modal Bộ Lọc -->
<div class="modal" tabindex="-1" role="dialog" *ngIf="showFilterModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Bộ Lọc</h5>
        <button type="button" class="close" aria-label="Close" (click)="closeFilterModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- Level 1 Categories as tabs -->
        <ul class="nav nav-tabs">
          <li class="nav-item" *ngFor="let category of categories; let i = index">
            <a class="nav-link" [class.active]="i === tempActiveCategory"
               (click)="tempActiveCategory = i; updateModalCategories()">
              {{ category.name }}
            </a>
          </li>
        </ul>

        <!-- Level 2 and 3 Categories as checkboxes -->
        <div class="tab-content">
          <div class="tab-pane fade show active">
            <div class="row">
              <div class="col-6" *ngFor="let subCategory of subCategories">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="{{subCategory.id}}"
                         [(ngModel)]="subCategory.isChecked"
                         (change)="toggleSubCategory(subCategory.id, true)">
                  <label class="form-check-label" for="{{subCategory.id}}">
                    {{ subCategory.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Footer with Apply and Reset Buttons -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="resetFilters()">Xóa hết</button>
        <button type="button" class="btn btn-primary" (click)="applyFilters()">Hiện tất cả kết quả</button>
      </div>
    </div>
  </div>
</div>
