import { Component } from '@angular/core';

@Component({
  selector: 'app-regulation',
  templateUrl: './regulation.component.html',
  styleUrl: './regulation.component.css'
})
export class RegulationComponent {

}
