import {Component, OnInit} from '@angular/core';
import {BlogServicesService} from '../../services/blog-services.service';

@Component({
  selector: 'app-suppliers-partner-list',
  templateUrl: './suppliers-partner-list.component.html',
  styleUrls: ['./suppliers-partner-list.component.css'],
})
export class SuppliersPartnerListComponent implements OnInit {
  webConfig: any = {};
  suppliers: any[] = []; // Danh sách nhà cung cấp
  industries: any[] = []; // Ngành nghề
  locations: any[] = []; // Địa chỉ (tỉnh/thành)
  des_main = '';
  des_extra = '';
  // Dữ liệu form tìm kiếm
  searchParams = {
    page: 1,
    size: 8,
    industry: '', // Giá trị mặc định
    location: '', // Giá trị mặc định
    name: '', // Từ khóa tìm kiếm
  };

  // Tổng số trang và trang hiện tại để điều khiển nút "Xem thêm"
  totalPages: number = 1; // Tổng số trang
  currentPage: number = 1; // Trang hiện tại

  constructor(private blogService: BlogServicesService) {
  }

  ngOnInit(): void {
    this.loadWebConfig();
    this.getListSuppliers(); // Gọi API danh sách nhà cung cấp khi load trang
    this.getCategoryLevel1(); // Gọi API ngành nghề
    this.getVietnamProvinces(); // Gọi API địa chỉ
  }

  loadWebConfig() {
    this.blogService.getWebConfig().subscribe((response) => {
      if (response.errorCode === 0) {
        this.webConfig = response.data;
        this.extractMenuDescription()
      }
    });
  }

  extractMenuDescription() {
    const partnerMenu = this.webConfig.menus.find(menu => menu.end_point === 'doi-tac');
    if (partnerMenu) {
      this.des_main = partnerMenu.des_main;
      // Assuming des_extra is another property you might be interested in
      this.des_extra = partnerMenu.des_extra || ''; // Default to empty if not available
    }
  }

  // Lấy danh sách nhà cung cấp
  getListSuppliers() {
    const payload = {page: this.searchParams.page, size: this.searchParams.size};
    this.blogService.getListSuppliers(payload).subscribe((response) => {
      if (response.errorCode === 0) {
        this.suppliers = response.data;
        this.totalPages = response.index.total_pages; // Cập nhật tổng số trang
        this.currentPage = response.index.current_page; // Cập nhật trang hiện tại
      }
    });
  }

  // Lấy danh sách ngành nghề
  getCategoryLevel1() {
    this.blogService.getCategorySuppliers().subscribe((response) => {
      if (response.errorCode === 0) {
        this.industries = response.data;
      }
    });
  }

  // Lấy danh sách tỉnh/thành
  getVietnamProvinces() {
    this.blogService.getVietnamProvinces().subscribe((response) => {
      if (response.errorCode === 0) {
        this.locations = response.data;
      }
    });
  }

  // Tìm kiếm nhà cung cấp dựa trên form tìm kiếm
  searchListSuppliers() {
    const payload = {
      page: this.searchParams.page,
      size: this.searchParams.size,
      industry: this.searchParams.industry,
      location: this.searchParams.location,
      name: this.searchParams.name,
    };

    this.blogService.searchListSuppliers(payload).subscribe((response) => {
      if (response.errorCode === 0) {
        this.suppliers = response.data;
        this.totalPages = response.index.total_pages; // Cập nhật tổng số trang
        this.currentPage = response.index.current_page; // Cập nhật trang hiện tại
      }
    });
  }

  // Tải thêm dữ liệu (Load More)
  loadMore() {
    if (this.currentPage < this.totalPages) {
      this.searchParams.page += 1;
      this.blogService.searchListSuppliers({
        ...this.searchParams,
      }).subscribe((response) => {
        if (response.errorCode === 0) {
          this.suppliers = [...this.suppliers, ...response.data]; // Thêm dữ liệu mới vào danh sách cũ
          this.currentPage = response.index.current_page; // Cập nhật trang hiện tại
        }
      });
    }
  }

  // Kiểm tra xem có còn trang để tải thêm không
  canLoadMore() {
    return this.currentPage < this.totalPages;
  }
}
