<!-- About -->
<section class="aboutpage-con position-relative">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-xl-7 col-lg-6 col-md-12 col-sm-12 col-12 text-lg-left text-center">
                <div class="about_wrapper position-relative" data-aos="zoom-in">
                    <figure class="about-image mb-0">
                        <img [src]="config.avatar_url" alt="image" class="img-fluid">
                    </figure>
                </div>
            </div>
            <div class="col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12">
                <div class="about_content" data-aos="fade-up">
                    <h6>Tác giả trang Web</h6>
                    <h2>{{config.name}}</h2>
                    <p class="text-size-16 text">{{config.description}}
                    </p>
                    <a [href]="config.facebook" class="text-decoration-none all_button">Facebook</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Activity -->
<section class="activity-con position-relative" [ngStyle]="{'background-image': 'url(' + config.experience_avatar_url + ')'}">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1 order-2">
                <div class="activity_content" data-aos="fade-up">
                    <h2 >Thành tựu và Kinh Nghiệm</h2>
                    <p class="text-white text-size-16 text">{{config.experience}}
                    </p>
                    <ul class="list-unstyled mb-0">
                        <li>
                            <span class="value"><span class="counter">{{config.experience_year}}</span></span>
                            <p class="text-size-14 mb-0">Khách hàng</p>
                        </li>
                        <li>
                            <span class="value"><span class="counter">{{config.experience_customer}}</span></span>
                            <p class="text-size-14 mb-0">Năm kinh nghiệm</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-2 order-1">
                <div class="activity_wrapper position-relative" data-aos="zoom-in">
                </div>
            </div>
        </div>
    </div>
    <figure class="activity-bottomimage mb-0">
        <img src="assets/images/activity-bottomimage.png" alt="image" class="img-fluid">
    </figure>
</section>

<!-- Testimonial -->
<section class="testimonial-con position-relative" [ngStyle]="{'background-image': 'url(' + config.testimonial_avatar_url + ')'}">
    <div class="container">
        <div class="row">
            <div class="col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12">
                <div class="testimonial_wrapper position-relative" data-aos="zoom-in">
                    <figure class="testimonial-circle image mb-0">
                        <img [src]="config.testimonial_5" alt="image" class="img-fluid">
                    </figure>
                    <figure class="testimonial-image1 image mb-0">
                        <img [src]="config.testimonial_1"  alt="image" class="img-fluid">
                    </figure>
                    <figure class="testimonial-image2 image mb-0">
                        <img [src]="config.testimonial_2"  alt="image" class="img-fluid">
                    </figure>
                    <figure class="testimonial-image3 image mb-0">
                        <img [src]="config.testimonial_3"  alt="image" class="img-fluid">
                    </figure>
                    <figure class="testimonial-image4 image mb-0">
                        <img [src]="config.testimonial_4"  alt="image" class="img-fluid">
                    </figure>
                </div>
            </div>
            <div class="col-xl-7 col-lg-6 col-md-12 col-sm-12 col-12">
                <div class="testimonial_contentwrapper" data-aos="fade-up">
                    <div class="testimonial_content">
                        <h2>Khách hàng nói về tôi</h2>
                    </div>
                    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <div class="testimonial-box">
                                    <div class="content-box">
                                        <p class="text-size-16">{{feed_back[0].content}}
                                        </p>
                                        <div class="content">
                                            <figure class="testimonial-quote">
                                                <img src="./assets/images/testimonial-quote.png" alt="image" class="img-fluid">
                                            </figure>
                                            <div class="designation-outer">
                                                <span class="name">{{feed_back[0].name}}</span>
                                                <span class="review">{{feed_back[0].company_name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" *ngFor="let item of feed_back.slice(1)">
                                <div class="testimonial-box">
                                    <div class="content-box">
                                        <p class="text-size-16">{{item.content}}
                                        </p>
                                        <div class="content">
                                            <figure class="testimonial-quote">
                                                <img src="./assets/images/testimonial-quote.png" alt="image" class="img-fluid">
                                            </figure>
                                            <div class="designation-outer">
                                                <span class="name">{{item.name}}</span>
                                                <span class="review">{{item.company_name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pagination-outer">
                            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                <i class="fa-solid fa-arrow-left"></i>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                <i class="fa-solid fa-arrow-right"></i>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Partner -->
<section class="partner-con">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="partner_content text-center" data-aos="fade-up">
                    <h2>Đối tác</h2>
                </div>
            </div>
            <ul class="list-unstyled mb-0" data-aos="fade-up">
                <li *ngFor="let item of partner">
                    <figure class="image-logo-partner mb-0">
                        <img [src]="item.logo" alt="image" class="img-fluid">
                    </figure>
                </li>
            </ul>
        </div>
    </div>
</section>

<app-leave-comment></app-leave-comment>
