<div class="sub_banner position-relative" *ngIf="!isLoading">
  <!-- Sub banner -->
  <section class="sub_banner_con" [style.background-image]="'url(' + eventDetail.thumbnail_url + ')'">
  </section>
</div>

<!-- Event detail -->
<section class="singleblog-section blogpage-section" *ngIf="!isLoading">
  <div class="container">
        <!-- Breadcrumb Navigation -->
      <div class="breadcrumb-nav ml-2">
        <a href="/" class="text-decoration-none">
          <span class="mr-2">Trang chủ</span>
        </a>
        <i class="fa-solid fa-chevron-right"></i> <!-- Mũi tên -->
        <a [href]="end_point" class="text-decoration-none">
          <span class="mr-2">{{ des_name }}</span>
        </a>
        <i class="fa-solid fa-chevron-right"></i> <!-- Mũi tên -->
        <span class="mr-2 text-orange">{{ eventDetail.name }}</span>
      </div>

      <!-- Category Tag -->
      <div class="category-tags ml-2">
          <span class="category-tag">{{ eventDetail.category }}</span>
      </div>

    <div class="row">
      <div class="col-lg-7 col-md-12 col-sm-12 col-12">
        <div class="main-box">
          <div class="content1" data-aos="fade-up" data-aos-duration="700">
            <h2 class="pc">{{ eventDetail.name }}</h2>
            <h2 class="mobile justify-content-center">{{ eventDetail.name }}</h2>
            <div class="event-detail-box p-3 mb-4 mobile">
              <ul class="list-unstyled">
                <li class="d-flex align-items-center mb-3">
                  <i class="fa-solid fa-calendar-days text-orange"></i>
                  <span class="ms-2">Ngày và giờ<br><p>{{ eventDetail.event_start }}
                    - {{ eventDetail.event_stop }}</p></span>
                </li>
                <li class="d-flex align-items-center mb-3">
                  <i class="fa-solid fa-location-dot text-orange"></i>
                  <span class="ms-2">Địa điểm<br><p>{{ eventDetail.location }}</p></span>
                </li>
                <li class="d-flex align-items-center mb-3">
                  <i class="fa-solid fa-ticket text-orange"></i>
                  <span class="ms-2">Giá vé<br>
                    <p>{{ eventDetail.event_price }}</p></span>
                </li>
                <li class="d-flex align-items-center">
                  <i class="fa-solid fa-globe text-orange"></i>
                  <a [href]="eventDetail.event_website" target="_blank"><span class="ms-2">Website</span></a>
                </li>
              </ul>
            </div>
            <div class="span-fa-outer-con pc">
              <i class="fa-solid fa-calendar-days"></i>
              <span class="mb-0 text-size-13">{{ eventDetail.event_start }} - {{ eventDetail.event_stop }}</span>
            </div>
            <h3 class="mobile mb-5"> Về sự kiện</h3>
            <p class="text-size-16" [innerHTML]="eventDetail.content"></p>
          </div>

          <!-- Ảnh chính -->
          <figure class="image1 mb-3" data-aos="fade-up" data-aos-duration="700">
            <img [src]="eventDetail.thumbnail_url" alt="Event Image" class="img-fluid" loading="lazy">
          </figure>
          <!-- Related Tags -->
          <div class="content4" data-aos="fade-up" data-aos-duration="700">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="tag">
                  <h4>Thẻ liên quan</h4>
                  <ul class="mb-0 list-unstyled">
                    <li *ngFor="let tag of eventDetail.tags">
                      <a class="button text-decoration-none" href="/tags/{{ tag.id }}">{{ tag.name }}</a>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- Social Share -->
              <!-- Social Share -->
              <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="icon">
                  <h4>Chia sẻ</h4>
                  <div class="social-icons position-absolute">
                    <ul class="mb-0 list-unstyled">
                      <!-- Facebook Share -->
                      <li>
                        <a href="https://www.facebook.com/sharer/sharer.php?u={{ currentUrl }}"
                           class="text-decoration-none" target="_blank">
                          <i class="fa-brands fa-facebook-f social-networks"></i>
                        </a>
                      </li>
                      <!-- Twitter (X) Share -->
                      <li>
                        <a href="https://twitter.com/intent/tweet?url={{ currentUrl }}&text=Check%20this%20out!"
                           class="text-decoration-none" target="_blank">
                          <i class="fa-brands fa-x-twitter social-networks"></i>
                        </a>
                      </li>
                      <!-- LinkedIn Share -->
                      <li>
                        <a href="https://www.linkedin.com/shareArticle?mini=true&url={{ currentUrl }}"
                           class="text-decoration-none" target="_blank">
                          <i class="fa-brands fa-linkedin-in social-networks"></i>
                        </a>
                      </li>
                      <!-- Instagram không hỗ trợ trực tiếp chia sẻ URL -->
                      <li>
                        <a href="https://www.threads.net/" class="text-decoration-none" target="_blank">
                          <i class="fa-brands fa-threads social-networks"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-5 col-md-12 col-sm-12 col-12 column pc inline-block">
        <div class="event-detail-box p-3 mb-4">
          <h5 class="mb-3">THÔNG TIN NHANH</h5>
          <ul class="list-unstyled">
            <li class="d-flex align-items-center mb-3">
              <i class="fa-solid fa-calendar-days text-orange"></i>
              <span class="ms-2">Ngày và giờ<br>
                <p>{{ eventDetail.event_start }} - {{ eventDetail.event_stop }}</p></span>
            </li>
            <li class="d-flex align-items-center mb-3">
              <i class="fa-solid fa-location-dot text-orange"></i>
              <span class="ms-2">Địa điểm<br>
                <p>{{ eventDetail.location }}</p></span>
            </li>
            <li class="d-flex align-items-center mb-3">
              <i class="fa-solid fa-ticket text-orange"></i>
              <span class="ms-2">Giá vé<br><p>{{ eventDetail.event_price }}</p></span>
            </li>
            <li class="d-flex align-items-center">
              <i class="fa-solid fa-globe text-orange"></i>
              <span class="ms-2">Website<br>
                <a [href]="eventDetail.event_website" target="_blank">{{ eventDetail.event_website }}</a>
              </span>
            </li>
          </ul>
        </div>

        <!-- Thư viện ảnh (Image Gallery) -->
        <div class="image-gallery-box" *ngIf="eventDetail.photo_gallery.length > 0">
          <h5 class="mb-3">THƯ VIỆN ẢNH</h5>
          <div class="row g-2">
            <div class="col-4 mb-1" *ngFor="let photo of eventDetail.photo_gallery">
              <img [src]="photo.url" alt="Image" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Loading Indicator -->
<div *ngIf="isLoading" class="d-flex justify-content-center align-items-center" style="min-height: 300px;">
  <p>Loading...</p>
</div>


<!-- Các sự kiện khác bạn có thể thích -->
<section class="related-events-section pc" *ngIf="!isLoadingRelated">
  <div class="container">
    <div class="main-box">
      <h2 class="related-events-title">Các sự kiện khác bạn có thể thích</h2>
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-12 mb-4" *ngFor="let event of relatedEvents">
          <div class="event-card">
            <div class="image-container">
              <a [href]="event.url">
                <img [src]="event.thumbnail_url" alt="Event Image">
                <span class="badge-category">{{ event.category_name }}</span>
              </a>
            </div>
            <div class="event-info">
              <h3 class="event-date">{{ event.event_start }} - {{ event.event_stop }}</h3>
              <a [href]="event.url" target="_blank" class="text-decoration-none">
                <p class="event-title">{{ event.name }}</p>
              </a>
              <p class="event-location"><i class="fa-solid fa-location-dot"></i> {{ event.location }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="blog-posts blogpage-section mobile">
  <div class="sidebar sticky-sidebar col-lg-3">
    <div class="theiaStickySidebar">
      <h2 class="related-events-title">Các sự kiện khác bạn có thể thích</h2>
      <div class="widget">
        <div class="tabs">
          <div class="tab-content" id="tabs-posts-content" data-aos="fade-up"
               data-aos-duration="700">
            <div class="tab-pane fade show active">
              <div class="post-thumbnail-list">
                <div class="post-thumbnail-entry" *ngFor="let event of relatedEvents">
                  <img [src]="event.thumbnail_url" alt="Event Image" loading="lazy">
                  <div class="post-thumbnail-content">
                    <a [href]="event.url" target="_blank" class="text-decoration-none mb-2">
                      {{ event.name }}
                    </a>
                    <span class="post-category mt-7"><i class="fa fa-calendar-days mr-2"></i>{{ event.event_start }} - {{ event.event_stop }}</span>
                    <br>
                    <p><i class="fa fa-location-dot mr-2"></i>{{ event.location }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Loading Indicator -->
<div *ngIf="isLoadingRelated" class="d-flex justify-content-center align-items-center" style="min-height: 300px;">
  <p>Loading related events...</p>
</div>
