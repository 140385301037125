import {Component, OnInit} from '@angular/core';
import {BlogServicesService} from '../../services/blog-services.service';

@Component({
  selector: 'app-list-event',
  templateUrl: './list-event.component.html',
  styleUrl: './list-event.component.css'
})
export class ListEventComponent implements OnInit {
  webConfig: any = {};
  des_main = '';
  des_extra = '';
  events: any[] = []; // Lưu danh sách sự kiện
  page = 1;  // Trang hiện tại
  size = 8;  // Số lượng sự kiện mỗi trang
  timeFilter = 'all';  // Giá trị lọc theo thời gian
  totalPages = 1;  // Tổng số trang
  isLoading = false; // Trạng thái tải dữ liệu
  totalRecords = 0;  // Tổng số bản ghi

  constructor(private blogService: BlogServicesService) {
  }

  ngOnInit(): void {
    this.loadWebConfig();
    this.loadEvents();
  }

  loadWebConfig() {
    this.blogService.getWebConfig().subscribe(response => {
      if (response.errorCode === 0) {
        this.webConfig = response.data;
        this.extractMenuDescription();
      }
    });
  }

  extractMenuDescription() {
    const partnerMenu = this.webConfig.menus.find(menu => menu.end_point === 'su-kien');
    if (partnerMenu) {
      this.des_main = partnerMenu.des_main;
      // Assuming des_extra is another property you might be interested in
      this.des_extra = partnerMenu.des_extra || ''; // Default to empty if not available
    }
  }

  // Hàm gọi API lấy danh sách sự kiện
  loadEvents(loadMore: boolean = false): void {
    this.isLoading = true; // Bắt đầu tải dữ liệu

    const payload = {
      page: this.page,
      size: this.size,
      time_str: this.timeFilter, // Thời gian lọc
    };
    console.log(payload);
    this.blogService.getListEvent(payload).subscribe(
      (response) => {
        if (response.errorCode === 0) {
          // Gộp các sự kiện mới với danh sách cũ nếu là Load More
          this.events = loadMore ? [...this.events, ...response.data] : response.data;

          // Cập nhật thông tin phân trang
          this.totalPages = response.index.total_pages;
          this.totalRecords = response.index.total_records;
        }
        this.isLoading = false; // Hoàn tất tải
      },
      (error) => {
        console.error('Error loading events:', error);
        this.isLoading = false;
      }
    );
  }

  // Hàm xử lý khi chọn bộ lọc thời gian
  onFilterChange(time_str: string): void {
    this.timeFilter = time_str;
    this.page = 1; // Reset lại trang
    this.loadEvents(); // Gọi lại API
  }

  // Hàm xử lý khi bấm Load More
  loadMoreEvents(): void {
    if (this.page < this.totalPages) {
      this.page++; // Tăng trang hiện tại
      this.loadEvents(true); // Gọi API với flag loadMore = true
    }
  }

}
