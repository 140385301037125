import {Component} from '@angular/core';
import {BlogServicesService} from '../../services/blog-services.service';
import {ActivatedRoute} from '@angular/router';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrl: './blog-detail.component.css'
})
export class BlogDetailComponent {
  blog: any = {}; // Store blog data here
  relatedPosts: any[] = [];
  backgroundImageUrl = ''; // To store the background image URL
  url = '';
  currentUrl = '';
  webConfig: any = {};
  des_main = '';
  des_extra = '';
  des_name = '';
  end_point = '';

  constructor(
    private route: ActivatedRoute,
    private blogService: BlogServicesService,
    private titleService: Title
  ) {
  }

  ngOnInit(): void {
    this.loadWebConfig()
    this.currentUrl = window.location.href;
    // Get the 'id' parameter from the route
    const url = this.route.snapshot.paramMap.get('id');
    if (url) {
      this.url = url;
      this.fetchBlogDetail(url);
      this.checkAndUpdateView(`/bai-viet/${this.url}`);
    }
  }

  loadWebConfig() {
    this.blogService.getWebConfig().subscribe(response => {
      if (response.errorCode === 0) {
        this.webConfig = response.data;
        this.extractMenuDescription();
      }
    });
  }

  extractMenuDescription() {
    const partnerMenu = this.webConfig.menus.find(menu => menu.end_point === 'bai-viet');
    if (partnerMenu) {
      this.des_main = partnerMenu.des_main;
      this.des_name = partnerMenu.name;
      this.end_point = partnerMenu.end_point;
      // Assuming des_extra is another property you might be interested in
      this.des_extra = partnerMenu.des_extra || ''; // Default to empty if not available
    }
  }

  // Fetch blog details from the API
  fetchBlogDetail(url: string): void {
    const payload = {url: `/bai-viet/${url}`};

    this.blogService.getBlogDetail(payload).subscribe(
      (response: any) => {
        if (response.errorCode === 0) {
          this.blog = response.data;
          this.backgroundImageUrl = this.blog.thumbnail;
          this.loadRelatedPosts();
          this.titleService.setTitle(response.data.name)
        }
      }, (error) => {
      });
  }

  // Load related posts based on category levels
  loadRelatedPosts(): void {
    const payload: any = {
      url: `/bai-viet/${this.url}`,
      page: 1,
      size: 4,
    };

    // Prioritize categories: level 3 > level 2 > level 1
    if (this.blog.category_level_3) {
      payload.level_3 = this.blog.category_level_3;
    } else if (this.blog.category_level_2) {
      payload.level_2 = this.blog.category_level_2;
    } else if (this.blog.category_level_1) {
      payload.level_1 = this.blog.category_level_1;
    }

    // Call the API to fetch related posts
    this.blogService.getListBlogByCategory(payload).subscribe(
      (response: any) => {
        if (response.errorCode === 0) {
          this.relatedPosts = response.data; // Store the related posts
        }
      },
      (error) => {
      }
    );
  }

  checkAndUpdateView(blogId: any): void {
    const viewKey = `viewed_blog_${blogId}`; // Unique key for this blog in LocalStorage

    // Check if the blog has already been viewed in LocalStorage
    if (!sessionStorage.getItem(viewKey)) {
      this.updateView();
      sessionStorage.setItem(viewKey, 'true');
    }
  }

  updateView(): void {
    const payload = {url: `/bai-viet/${this.url}`};
    this.blogService.updateBlogView(payload).subscribe(response => {
    });
  }
}
