import { Component } from '@angular/core';
import { BlogServicesService } from '../../services/blog-services.service';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.css']
})
export class NewsListComponent {
  webConfig: any = {};
  des_main = '';
  des_extra = '';
  relatedEvents: any[] = [];
  news: any[] = []; // data
  page = 1;
  size = 12;
  totalPage = 1; // Total pages available

  newsList: any[] = []; // Danh sách tin tức
  popularNewsList: any[] = []; // Danh sách tin tức phổ biến

  constructor(private blogService: BlogServicesService) {}

  ngOnInit(): void {
    this.loadWebConfig();
    this.initNews();
  }

  loadWebConfig() {
    this.blogService.getWebConfig().subscribe(response => {
      if (response.errorCode === 0) {
        this.webConfig = response.data;
        this.extractMenuDescription();
      }
    });
  }

  extractMenuDescription() {
    const partnerMenu = this.webConfig.menus.find(menu => menu.end_point === 'xu-huong');
    if (partnerMenu) {
      this.des_main = partnerMenu.des_main;
      this.des_extra = partnerMenu.des_extra || ''; // Default to empty if not available
    }
  }

  initNews(): void {
    // Lấy danh sách tin tức
    const new_rq = {
      page: this.page,
      size: this.size,
      order: ''
    };
    this.getNewsList(new_rq, 'newsList');

    // Lấy danh sách tin phổ biến
    const popular_rq = {
      page: 1,
      size: 6,
      order: 'view'
    };
    this.getNewsList(popular_rq, 'popularNewsList');
  }

  loadMore(): void {
    if (this.canLoadMore()) {
      this.page++;
      const new_rq = {
        page: this.page,
        size: this.size,
        order: ''
      };
      this.getNewsList(new_rq, 'newsList', true);
    }
  }

  // Check if there are more pages to load
  canLoadMore(): boolean {
    return this.page < this.totalPage;
  }

  getNewsList(payload, targetList: string, append: boolean = false): void {
    this.blogService.getListNew(payload).subscribe(response => {
      if (response.errorCode === 0) {
        if (append) {
          // Nếu append = true, thêm vào danh sách hiện tại
          this[targetList] = [...this[targetList], ...response.data];
        } else {
          this[targetList] = response.data;
        }

        // Cập nhật tổng số trang (nếu có trong response)
        if (targetList == 'newsList') {
          this.totalPage = response.index.total_page;
        }
      }
    });
  }
}
