<!--list ncc-partner-->
<section class="service-con position-relative">
  <div class="container">
    <div class="row">
      <div class="col-12 position-relative">
        <div class="service_content text-center" data-aos="fade-up">
          <!-- Tiêu đề và mô tả -->
          <h2>{{ des_main }}</h2>
          <p>{{des_extra}}</p>
        </div>

        <!--banner & search-->
        <div class="banner-con position-relative">
          <div >
            <div class="row">
              <div class="col-lg-10 col-12 mx-auto">
                <div class="banner_content text-center" data-aos="fade-up">
                  <div class="banner-box">
                    <form (ngSubmit)="searchListSuppliers()">
                      <div class="form-group float-left mb-0">
                        <div class="upper">
                          <i class="fa-solid fa-briefcase"></i>
                          <label for="industry">Ngành nghề</label>
                        </div>
                        <select class="form_style" id="industry" name="industry" [(ngModel)]="searchParams.industry">
                          <option value="">-Tất cả-</option>
                          <option *ngFor="let industry of industries" [value]="industry.id">
                            {{ industry.name }}
                          </option>
                        </select>
                      </div>

                      <div class="form-group float-left mb-0">
                        <div class="upper">
                          <i class="fa-solid fa-location-dot"></i>
                          <label for="location">Địa chỉ</label>
                        </div>
                        <select class="form_style" id="location" name="location" [(ngModel)]="searchParams.location">
                          <option value="">-Tất cả-</option>
                          <option *ngFor="let location of locations" [value]="location.id">
                            {{ location.name }}
                          </option>
                        </select>
                      </div>

                      <div class="form-group float-left mb-0">
                        <div class="upper">
                          <i class="fa-solid fa-tags"></i>
                          <label for="name">Từ khóa</label>
                        </div>
                        <input
                          type="text"
                          class="form_style"
                          id="name"
                          placeholder="Nhập từ khóa"
                          name="name"
                          [(ngModel)]="searchParams.name"
                        />
                      </div>

                      <button type="submit"><i class="search fa-solid fa-magnifying-glass"></i></button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Danh sách nhà cung cấp -->
        <div class="row" data-aos="fade-up">
          <div class="col-lg-3 col-md-6 col-sm-6 col-12" *ngFor="let supplier of suppliers">
            <div class="service-box">
              <a [href]="supplier.url" target="_blank" class="text-decoration-none">
                <figure class="icon">
                  <img [src]="supplier.logo" alt="image" class="img-fluid"/>
                </figure>
                <h4>{{ supplier.name }}</h4>
              </a>
              <p class="text-size-16 description">{{ supplier.description }}</p>
              <div class="contact-info">
                <p class="phone" ngbTooltip="{{ supplier.industry }}" placement="auto"><i class="fa-solid fa-industry"></i> Ngành: {{ supplier.industry }}</p>
                <p class="phone"><i class="fa-solid fa-phone"></i> Điện thoại: {{ supplier.phone }}</p>
              </div>
              <a [href]="supplier.url" target="_blank" class="text-decoration-none learn_more">
                Xem chi tiết<i class="fa-solid fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>

        <!-- Load More Button -->
        <div class="load-more d-flex justify-content-center mt-4" *ngIf="canLoadMore()" data-aos="fade-up"
             data-aos-duration="700">
          <a class="default-btn hover-effect" (click)="loadMore()">Xem thêm <i class="fa-solid fa-arrow-down"></i></a>
        </div>
      </div>
    </div>
  </div>
</section>
