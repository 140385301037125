<section class="service_detail position-relative">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="main-box">
          <h3 data-aos="fade-up">QUY ĐỊNH SỬ DỤNG</h3>
          <p class="text1 text-size-16" data-aos="fade-up">Chào mừng bạn đến với website kienthucmoquan.vn. Trước khi sử dụng trang web này, vui lòng đọc kỹ các điều khoản và điều kiện sau đây. Khi bạn truy cập và sử dụng trang web, điều đó đồng nghĩa với việc bạn chấp nhận các quy định sử dụng dưới đây.</p>

          <p class="text2 text-size-16" style="margin-top: 15px !important; margin-bottom: 0px !important;" data-aos="fade-up"><strong>1. Quyền sở hữu nội dung</strong></p>
          <p class="text-size-16" style="margin-top: 5px !important; margin-bottom: 5px;" data-aos="fade-up">Tất cả nội dung trên trang web kienthucmoquan.vn, bao gồm nhưng không giới hạn ở văn bản, hình ảnh, video, đồ họa và các tài liệu khác, đều thuộc quyền sở hữu của chúng tôi hoặc được cấp phép từ các nguồn hợp pháp. Bạn không được sao chép, tái bản, phân phối hoặc sử dụng bất kỳ nội dung nào mà không có sự đồng ý trước bằng văn bản từ chúng tôi.</p>

          <p class="text2 text-size-16" style="margin-top: 15px !important; margin-bottom: 0px !important;" data-aos="fade-up"><strong>2. Sử dụng nội dung và yêu cầu trích dẫn nguồn</strong></p>
          <p class="text-size-16" style="margin-top: 5px !important; margin-bottom: 5px;" data-aos="fade-up">Chúng tôi cho phép sử dụng nội dung trên trang web với điều kiện bạn phải trích dẫn rõ ràng nguồn từ kienthucmoquan.vn. Việc trích dẫn phải bao gồm tên trang web và liên kết đến nội dung gốc. Bất kỳ hành vi sử dụng nội dung mà không trích dẫn đúng yêu cầu sẽ bị coi là vi phạm quyền sở hữu trí tuệ.</p>

          <p class="text2 text-size-16" style="margin-top: 15px !important; margin-bottom: 0px !important;" data-aos="fade-up"><strong>3. Sử dụng hợp pháp</strong></p>
          <p class="text-size-16" style="margin-top: 5px !important; margin-bottom: 5px;" data-aos="fade-up">Bạn cam kết chỉ sử dụng trang web này cho các mục đích hợp pháp và không vi phạm bất kỳ luật pháp nào hiện hành. Bạn không được sử dụng trang web để truyền tải bất kỳ nội dung bất hợp pháp, xâm phạm quyền lợi của người khác hoặc gây tổn hại đến danh tiếng của chúng tôi.</p>

          <p class="text2 text-size-16" style="margin-top: 15px !important; margin-bottom: 0px !important;" data-aos="fade-up"><strong>4. Bảo mật tài khoản</strong></p>
          <p class="text-size-16" style="margin-top: 5px !important; margin-bottom: 5px;" data-aos="fade-up">Nếu bạn tạo tài khoản trên kienthucmoquan.vn, bạn phải tự chịu trách nhiệm bảo mật thông tin tài khoản của mình, bao gồm tên đăng nhập và mật khẩu. Bạn đồng ý thông báo ngay lập tức cho chúng tôi về bất kỳ hành vi sử dụng trái phép tài khoản của bạn.</p>

          <p class="text2 text-size-16" style="margin-top: 15px !important; margin-bottom: 0px !important;" data-aos="fade-up"><strong>5. Chính sách quyền riêng tư</strong></p>
          <p class="text-size-16" style="margin-top: 5px !important; margin-bottom: 5px;" data-aos="fade-up">Chúng tôi cam kết bảo vệ thông tin cá nhân của bạn theo quy định của pháp luật. Để biết thêm chi tiết, vui lòng tham khảo Chính sách quyền riêng tư của chúng tôi.</p>

          <p class="text2 text-size-16" style="margin-top: 15px !important; margin-bottom: 0px !important;" data-aos="fade-up"><strong>6. Giới hạn trách nhiệm</strong></p>
          <p class="text-size-16" style="margin-top: 5px !important; margin-bottom: 5px;" data-aos="fade-up">Chúng tôi không chịu trách nhiệm về bất kỳ thiệt hại hoặc mất mát nào phát sinh từ việc sử dụng trang web hoặc dựa trên thông tin được cung cấp trên trang web. Mọi quyết định dựa trên các thông tin từ trang web là quyết định của riêng bạn.</p>

          <p class="text2 text-size-16" style="margin-top: 15px !important; margin-bottom: 0px !important;" data-aos="fade-up"><strong>7. Liên kết đến trang web của bên thứ ba</strong></p>
          <p class="text-size-16" style="margin-top: 5px !important; margin-bottom: 5px;" data-aos="fade-up">Trang web có thể chứa các liên kết đến trang web của bên thứ ba. Chúng tôi không chịu trách nhiệm về nội dung, chính sách bảo mật hoặc hành vi của các trang web đó. Bạn nên tự chịu rủi ro khi truy cập các liên kết này.</p>

          <p class="text2 text-size-16" style="margin-top: 15px !important; margin-bottom: 0px !important;" data-aos="fade-up"><strong>8. Thay đổi quy định sử dụng</strong></p>
          <p class="text-size-16" style="margin-top: 5px !important; margin-bottom: 5px;" data-aos="fade-up">Chúng tôi có quyền thay đổi, cập nhật quy định sử dụng bất kỳ lúc nào mà không cần thông báo trước. Các thay đổi sẽ có hiệu lực ngay khi được cập nhật trên trang web. Bạn nên thường xuyên kiểm tra mục này để đảm bảo rằng bạn hiểu và đồng ý với các quy định mới.</p>

          <p class="text2 text-size-16" style="margin-top: 15px !important; margin-bottom: 0px !important;" data-aos="fade-up"><strong>9. Quy định về bình luận và nội dung người dùng</strong></p>
          <p class="text-size-16" style="margin-top: 5px !important; margin-bottom: 5px;" data-aos="fade-up">Khi bạn đăng tải bình luận hoặc nội dung trên trang web, bạn chịu trách nhiệm hoàn toàn về nội dung đó. Nội dung không được phép vi phạm pháp luật, quyền lợi của bên thứ ba hoặc gây ra bất kỳ tranh chấp pháp lý nào. Chúng tôi có quyền xóa các nội dung vi phạm mà không cần báo trước.</p>

          <p class="text2 text-size-16" style="margin-top: 15px !important; margin-bottom: 0px !important;" data-aos="fade-up"><strong>10. Liên hệ</strong></p>
          <p class="text-size-16" style="margin-top: 5px !important; margin-bottom: 5px;" data-aos="fade-up">Nếu bạn có bất kỳ câu hỏi nào về quy định sử dụng, vui lòng liên hệ với chúng tôi qua email: kienthucmoquan&#64;gmail.com</p>
        </div>
      </div>
    </div>
  </div>
</section>
