import {Component} from '@angular/core';
import {BlogServicesService} from '../../services/blog-services.service';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrl: './about-me.component.css'
})
export class AboutMeComponent {
  config: any = {};
  feed_back: any[] = [];
  partner: any[] = [];

  constructor(private blogService: BlogServicesService) {
    this.loadConfig();
  }

  ngOnInit(): void {
  }

  loadConfig() {
    this.blogService.getAboutMeConfig().subscribe(response => {
      if (response.errorCode === 0) {
        this.config = response.data;
        this.feed_back = response.data.feed_back;
        this.partner = response.data.partner;
      }
    });
  }
}
