<div class="sub_banner position-relative">
  <!-- Sub banner -->
  <!--  <section class="sub_banner_con position-relative"-->
  <!--           [ngStyle]="{'background-image': 'url(' + webConfig.thumbnail_list_event_url + ')'}">-->
  <!--    <div class="container position-relative">-->
  <!--      <div class="row">-->
  <!--        <div class="col-xl-6 col-md-8 col-12 mx-auto">-->
  <!--          <div class="sub_banner_content" data-aos="fade-up">-->
  <!--            <h1 class="text-white">Danh sách sự kiện</h1>-->
  <!--            <p class="text-white text-size-16">Quis aute irure dolor in reprehenderit in voluptate velit esse cillum-->
  <!--              dolore noulla pariatur ccaecat curidatat aero.</p>-->
  <!--            <div class="box">-->
  <!--              <a href="" class="text-decoration-none">-->
  <!--                <span class="mb-0">Home</span>-->
  <!--              </a>-->
  <!--              <i class="arrow fa-solid fa-arrow-right"></i>-->
  <!--              <span class="mb-0 box_span">su-kien</span>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </section>-->
</div>

<section class="blog-posts blogpage-section background-gradient">
  <div class="container">
    <div class="justify-content-between align-items-center flex-column-mobile">
      <div>
        <!-- Tiêu đề và mô tả -->
        <h2 class="even-title">{{des_main}}</h2>

        <p class="even-description">{{des_extra}}</p>
      </div>
      <div class="event-filter">
        <select class="form-select" aria-label="Lọc sự kiện" (change)="onFilterChange($event.target.value)">
          <option value="all" selected>Tất cả các ngày</option>
          <option value="current_week">Tuần này</option>
          <option value="current_month">Tháng này</option>
          <option value="3_month">3 tháng tới</option>
        </select>
      </div>
    </div>

    <div class="main-box">
      <div class="row">
        <!-- Lặp qua danh sách sự kiện -->
        <div class="col-lg-3 col-md-6 col-sm-12 mb-4" *ngFor="let event of events">
          <div class="event-card">
            <div class="image-container">
              <a [href]="event.url">
                <img [src]="event.thumbnail_url" alt="Event Image"/>
                <span class="badge-category">{{ event.category_name }}</span>
              </a>
            </div>
            <div class="event-info">
              <h3 class="event-date">{{ event.event_start }} - {{ event.event_stop }}</h3>
              <a [href]="event.url" target="_blank" class="text-decoration-none">
                <p class="event-title" ngbTooltip="{{ event.name }}" placement="auto">{{ event.name }}</p>
              </a>
              <p class="event-location" ngbTooltip="{{ event.location }}" placement="auto">
                <i class="fa-solid fa-location-dot"></i> {{ event.location }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Loading Indicator -->
    <div *ngIf="isLoading" class="d-flex justify-content-center align-items-center" style="min-height: 300px;">
      <p>Loading...</p>
    </div>
    <!-- Load More Button -->
    <div class="load-more d-flex justify-content-center mt-4" *ngIf="page < totalPages">
      <a (click)="loadMoreEvents()" class="default-btn hover-effect">Xem thêm <i class="fa-solid fa-arrow-down"></i></a>
    </div>
  </div>
</section>

