import { Component } from '@angular/core';
import {BlogServicesService} from '../../services/blog-services.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {
  webConfig: any = {};

  constructor(private blogService: BlogServicesService) {
    this.loadWebConfig();
  }

  ngOnInit(): void {
  }

  loadWebConfig() {
    this.blogService.getWebConfig().subscribe(response => {
      if (response.errorCode === 0) {
        this.webConfig = response.data;
      }
    });
  }
}
