<!-- Sub banner -->
<!--<div class="sub_banner position-relative">-->
<!--  <section-->
<!--    class="sub_banner_con position-relative"-->
<!--    [ngStyle]="{ 'background-image': 'url(' + backgroundImageUrl + ')' }">-->
<!--    <div class="container position-relative">-->
<!--      <div class="row">-->
<!--        <div class="col-xl-6 col-md-8 col-12 mx-auto">-->
<!--          <div class="sub_banner_content" data-aos="fade-up">-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->
<!--</div>-->

<!-- Single Blog -->
<section class="singleblog-section blogpage-section">
  <div class="container">
    <!-- Breadcrumb Navigation -->
      <div class="breadcrumb-nav ml-2">
        <a href="/" class="text-decoration-none">
          <span class="mr-2">Trang chủ</span>
        </a>
        <i class="fa-solid fa-chevron-right"></i> <!-- Mũi tên -->
        <a [href]="end_point" class="text-decoration-none">
          <span class="mr-2">{{ des_name }}</span>
        </a>
        <i class="fa-solid fa-chevron-right"></i> <!-- Mũi tên -->
        <span class="mr-2 text-orange">{{ blog.name }}</span>
      </div>

      <!-- Category Tag -->
      <div class="category-tags ml-2">
        <ng-container *ngFor="let category of blog.category_name">
          <span class="category-tag">{{ category }}</span>
        </ng-container>
      </div>


    <!-- Main bai viet-->
    <div class="row">
      <div class="col-lg-8 col-md-12 col-sm-12 col-12">
        <div class="main-box">
          <!-- Blog content -->
          <div class="content1" data-aos="fade-up" data-aos-duration="700">
            <h2>{{ blog.name }}</h2>
            <div class="span-fa-outer-con">
              <i class="fa-solid fa-user"></i>
              <span class="text-size-14 text-mr">Tác giả: {{ blog.author }}</span>
              <i class="mb-0 calendar fa-solid fa-calendar-days"></i>
              <span class="mb-0 text-size-13">{{ blog.publication_date}}</span>
            </div>
            <div data-aos="fade-up" data-aos-duration="700" [innerHTML]="blog.content"></div>
            <!-- Display blog content -->
          </div>

          <!-- Blog Images (photo_gallery) -->
          <div class="grid-container" *ngIf="blog.photo_gallery?.length > 0">
            <div class="grid-item" *ngFor="let photo of blog.photo_gallery">
              <figure class="image1 mb-3" data-aos="fade-up" data-aos-duration="700">
                <img [src]="photo.url" alt="image" class="img-fluid" loading="lazy"/>
              </figure>
            </div>
          </div>

          <!-- Related Tags -->
          <div class="content4" data-aos="fade-up" data-aos-duration="700">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="tag">
                  <h4>Thẻ liên quan</h4>
                  <ul class="mb-0 list-unstyled">
                    <li *ngFor="let tag of blog.tags">
                      <a class="button text-decoration-none" href="/tags/{{ tag.id }}">{{ tag.name }}</a>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- Social Share -->
              <!-- Social Share -->
              <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="icon">
                  <h4>Chia sẻ</h4>
                  <div class="social-icons position-absolute">
                    <ul class="mb-0 list-unstyled">
                      <!-- Facebook Share -->
                      <li>
                        <a href="https://www.facebook.com/sharer/sharer.php?u={{ currentUrl }}"
                           class="text-decoration-none" target="_blank">
                          <i class="fa-brands fa-facebook-f social-networks"></i>
                        </a>
                      </li>
                      <!-- Twitter (X) Share -->
                      <li>
                        <a href="https://twitter.com/intent/tweet?url={{ currentUrl }}&text=Check%20this%20out!"
                           class="text-decoration-none" target="_blank">
                          <i class="fa-brands fa-x-twitter social-networks"></i>
                        </a>
                      </li>
                      <!-- LinkedIn Share -->
                      <li>
                        <a href="https://www.linkedin.com/shareArticle?mini=true&url={{ currentUrl }}"
                           class="text-decoration-none" target="_blank">
                          <i class="fa-brands fa-linkedin-in social-networks"></i>
                        </a>
                      </li>
                      <!-- Instagram không hỗ trợ trực tiếp chia sẻ URL -->
                      <li>
                        <a href="https://www.threads.net/" class="text-decoration-none" target="_blank">
                          <i class="fa-brands fa-threads social-networks"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

      <!-- Related Posts (Right Sidebar) -->
      <div class="col-lg-4 col-md-12 col-sm-12 col-12 column pc">
        <div class="box box5" data-aos="fade-up" data-aos-duration="700">
          <h4 class="text-center">Bài viết liên quan</h4>
          <!-- Loop through related posts -->
          <div *ngFor="let post of relatedPosts" class="feed">
            <div class="row">
              <div class="col-lg-4">
                <figure class="feed-image">
                  <img [src]="post.thumbnail_url" alt="image" class="img-fluid" loading="lazy"/>
                </figure>
              </div>
              <div class="col-lg-8">
                <div class="feed-content">
                  <a [href]="post.url" class="category-link"
                     ngbTooltip="{{ post.category_name }}">{{ post.category_name }}</a>
                  <a [href]="post.url" class="post-title mb-0">{{ post.name }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <section class="blog-posts blogpage-section mobile">
          <div class="sidebar sticky-sidebar col-lg-3">
            <div class="theiaStickySidebar">
              <h2 class="related-events-title">Bài viết liên quan</h2>
              <div class="widget">
                <div class="tabs">
                  <div class="tab-content" id="tabs-posts-content" data-aos="fade-up"
                       data-aos-duration="700">
                    <div class="tab-pane fade show active">
                      <div class="post-thumbnail-list">
                        <div class="post-thumbnail-entry" *ngFor="let event of relatedPosts">
                          <img [src]="event.thumbnail_url" alt="Event Image" loading="lazy">
                          <div class="post-thumbnail-content">
                            <a [href]="event.url" class="text-orange mb-2" style="margin-top: 15px !important;"
                               ngbTooltip="{{ event.category_name }}">{{ event.category_name }}</a>
                            <a [href]="event.url" target="_blank" class="text-decoration-none mb-2">
                              {{ event.name }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </div>
  </div>
</section>

<app-leave-comment></app-leave-comment>
