import {Component, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';
import {BlogServicesService} from '../../services/blog-services.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  webConfig: any = {};
  currentRoute: string = '';
  currentRouteStr: string = '';
  showSearch: boolean = false;
  isMenuOpen: boolean = false;
  searchQuery: string = '';

  constructor(private blogService: BlogServicesService,
              private eRef: ElementRef,
              private router: Router,
              private titleService: Title,
              private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.router.events.subscribe((val: any) => {
      if (val.url) {
        this.currentRoute = val.url;
      }
    });
    this.loadWebConfig();
  }

  loadWebConfig() {
    this.blogService.getWebConfig().subscribe(response => {
      if (response.errorCode === 0) {
        this.webConfig = response.data;
        this.setFavicon(response.data.icon)
        this.updateCurrentRouteStr(response.data)
      }
    });
  }

  updateCurrentRouteStr(data: any) {
    if (data.menus) {
      // Tìm kiếm endpoint khớp với currentRoute
      const matchedMenu = data.menus.find((menu: any) =>
        this.currentRoute.includes(menu.end_point)
      );
      if (matchedMenu) {
        this.titleService.setTitle(matchedMenu.name);
      } else {
        this.titleService.setTitle(data.title);// Hoặc giá trị mặc định nếu không tìm thấy
      }
    }
  }

  setFavicon(iconUrl: string): void {
    const link: HTMLLinkElement = this.renderer.selectRootElement('#dynamic-favicon');
    this.renderer.setAttribute(link, 'href', iconUrl);
  }

  // Hàm kiểm tra nếu endpoint của menu trùng với URL hiện tại
  isActive(menu: any): boolean {
    const regex = /^\/[^\/]+/; // Regex để trích xuất phần đầu tiên của đường dẫn sau dấu gạch chéo "/"
    const match = this.currentRoute.match(regex); // Áp dụng regex lên currentRoute

    let end_point = match ? match[0] : '';

    return end_point == '/' + menu.end_point;
  }

  // Toggle search input
  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  closeMenuMobile() {
    this.isMenuOpen = false;
  }

  // Toggle mobile menu
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  // Handle search logic
  search() {
    if (this.searchQuery) {
      this.router.navigate(['/tim-kiem'], {queryParams: {query: this.searchQuery}});
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    this.handleClickOutside(event);
  }

  // Close the search box when clicking outside
  handleClickOutside(event: Event) {
    const targetElement = event.target as HTMLElement;
    if (!this.eRef.nativeElement.contains(targetElement)) {
      this.showSearch = false;
      this.isMenuOpen = false;
    }
  }
}
