import { Component, OnInit } from '@angular/core';
import {ToastService} from '../../services/toast-service.service';


@Component({
  selector: 'app-toast',
  template: `
    <div *ngIf="show" class="toast" [class.show]="show">
      {{ message }}
    </div>
  `,
  styleUrls: ['./toast.component.css']
})
export class ToastComponent implements OnInit {
  message: string = '';
  show: boolean = false;

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    // Lắng nghe thông báo từ ToastService
    this.toastService.toastState.subscribe((message: string) => {
      this.message = message;
      this.show = true;

      // Tự động ẩn toast sau 3 giây
      setTimeout(() => {
        this.show = false;
      }, 3000);
    });
  }
}
